import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { AnimatedSparkles } from '@xing-com/crate-jobs-components-animated-sparkles';
import { useTimedCallback } from '@xing-com/crate-jobs-hooks';
import { mediaConfined } from '@xing-com/layout-tokens';
import { spaceXL, spaceXS, xdlColorTextSecondary } from '@xing-com/tokens';
import { Meta } from '@xing-com/typography';

const ANIMATION_TYPE_SPEED_MS = 18;
const THREE_DOTS_TYPE_SPEED_MS = 500;

type Props = { text: string; onType: (value: string) => void };
export const MatchingReasonHighlightLoading: React.FC<Props> = ({
  text,
  onType,
}) => {
  const timed = useTimedCallback({ speed: ANIMATION_TYPE_SPEED_MS });
  const { formatMessage } = useIntl();
  // Locale does not include the ...
  const loadingText = `${formatMessage({ id: 'JOBS_MATCHING_REASONS_LOADING' })}...`;

  const ref = useRef<HTMLParagraphElement | null>(null);
  const charIndexRef = useRef(0);

  const type = (): void => {
    if (!ref.current) {
      return;
    }

    onType(loadingText.slice(0, charIndexRef.current));

    if (charIndexRef.current < loadingText.length) {
      ++charIndexRef.current;
    } else {
      charIndexRef.current -= 3;
    }

    timed.run(type, {
      delay:
        charIndexRef.current <= loadingText.length - 3
          ? ANIMATION_TYPE_SPEED_MS
          : THREE_DOTS_TYPE_SPEED_MS,
    });
  };

  useEffect(() => {
    const initialTypeTimeout = setTimeout(() => {
      type();
      // Run once icon animation has finished
    }, 500);

    return () => {
      clearTimeout(initialTypeTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MRContainer>
      <MRSparklesContainer>
        <AnimatedSparkles color={xdlColorTextSecondary} shortAnimation />
      </MRSparklesContainer>
      <Meta
        noMargin
        ref={ref}
        color={xdlColorTextSecondary}
        data-testid="highlight-container"
      >
        {text}
      </Meta>
    </MRContainer>
  );
};

const MRContainer = styled.div`
  display: flex;
  gap: ${spaceXS};
  align-items: flex-start;
  min-height: ${spaceXL};

  @media ${mediaConfined} {
    align-items: center;
  }
`;

const MRSparklesContainer = styled.span`
  margin-top: ${spaceXS};

  @media ${mediaConfined} {
    margin-top: 0;
  }
`;
